<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row py-3 px-4" id="filter-card">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                    :helper-text="$t('general.search')"
                    title="general.search"
                    :placeholder="$t('general.search_filter')"
                    :model.sync="search"
                ></input-filter>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 my-2 row mx-0">
                <select-filter
                    :helper-text="$t('general.select_language')"
                    :title="$t('general.select_language')"
                    :placeholder="$t('general.select_language')"
                    :model.sync="language_id"
                    :values="languages"
                ></select-filter>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button @click="filterResult(null)"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="resetFilters"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <dashboard-box class="pb-10">
        <template v-slot:preview>
          <data-table
              class="relative"
              tableVariant="''"
              :hover="false"
              :outlined="false"
              :striped="false"
              :no-border-collapse="true"
              :borderless="true"
              :bordered="false"
              @onChangeStatus="onChangeStatus"
              @onClickEdit="onClickEdit"
              @hitBottom="onHitBottom"
              :items="formattedItem"
              :fields="fields"
              :per-page.sync="per_page"
              :page.sync="page"
          ></data-table>
          <div class="d-flex justify-content-end items-end fixed cursor-pointer" style="right: 10px; bottom: 10px;">
            <div class="w-60px h-60px"
                 @click="$router.push({name: createRouteName})">
              <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })">
                </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import DataTable from "@/assets/components/dataTable/DataTable";
import moment from 'moment';
import {LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";

import baseModule, {
  BASE_URL,
  ERROR,
  ITEMS,
  LOADING,
  MODULE_NAME,
  DELETE_ITEM,
  SUCCESS,
  UPDATE_ITEM,
  SET_ITEMS,
  HANDLE_INFINITE_SCROLL
} from "@/core/services/store/invoice-category/invoiceCategory.module";
import store from "@/core/services/store";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "InvoiceCategoryIndex",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, baseModule)
  },
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox
  },
  computed: {
    error() {
      return store.getters[_MODULE_NAME + '/' + ERROR];
    },
    loading() {
      return store.getters[_MODULE_NAME + '/' + LOADING];
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS];
    },
    items() {
      return store.getters[_MODULE_NAME + '/' + ITEMS];
    },
    filters() {
      return {
        per_page: this.per_page,
        page: this.page,
        search: this.search,
        language_id: this.language_id
      }
    },
    formattedItem() {
      let results = [];
      if (this.items === null || this.items.data === null || this.items.data === undefined) return [];
      for (let i = 0; i < this.items.data.length; i++) {
        let currentItem = this.items.data[i];
        let createdAt = currentItem.created_at ? moment(currentItem.created_at, LARAVEL_DATE_TIME_FORMAT) : moment();
        results.push({
          id: currentItem.id,
          isActive: +currentItem.status === 1,
          name: this.getValueByDottedKey(currentItem, 'translations.0.name'),
          createdAt: createdAt.format(MOMENT_SYSTEM_DATE_FORMAT),
        })
      }
      return results;
    }
  },
  data() {
    return {
      page: 1,
      per_page: 25,
      search: '',
      state: null,
      language_id: null,
      languages: [],
      editRouteName: 'management.invoice-category.edit',
      createRouteName: 'management.invoice-category.create',
      fields: [
        {
          key: 'id',
          label: this.$t('general.id'),
          sortable: true,
          class: 'text-center border border-top-0 border-right-0 font-size-lg'
        },
        {
          key: 'name',
          label: this.$t('general.name'),
          sortable: true,
          class: 'text-center border border-top-0 border-right-0 font-size-lg'
        },
        {
          key: 'status',
          label: this.$t('general.status'),
          sortable: false,
          class: 'text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg '
        },
        {
          key: 'createdAt',
          label: this.$t('general.created_at'),
          sortable: false,
          class: 'text-center border border-top-0 border-right-0 font-size-lg'
        },
        {
          key: 'only_update',
          label: this.$t('general.actions'),
          sortable: false,
          class: 'text-center border border-top-0 border-right-0 font-size-lg'
        },
      ],
    };
  },
  methods: {
    onChangeStatus(id) {
      let item = this.items.data.find(item => item.id === id);
      item.status = !item.status;

      this.$store.dispatch(_MODULE_NAME + '/' + UPDATE_ITEM, {
        filters: this.filters,
        url: BASE_URL + '/' + id,
        contents: item
      }).then(result => {
        if(result.status) {

        }
      })
    },
    onHitBottom($state){
      if(this.loading) {
        return;
      }
      if ($state) {
        this.state = $state;
      }
      this.$store.dispatch(_MODULE_NAME + '/' + HANDLE_INFINITE_SCROLL, {
        filters: this.filters,
        url: BASE_URL,
        '$state': $state,
      }).then(result => {
        if(result.status && $state) {
          $state.loaded();
        }else{
          if('end' in result && $state){
            $state.complete();
          }
        }
      })
    },
    onClickDelete(id) {
      let self = this;
      this.sweetAlertConfirm(this.$t("general.are_you_sure")).then(result => {
        if(result) {
          let payload = {
            url: BASE_URL + '/' + id,
            id: id,
          }
          self.$store.dispatch(_MODULE_NAME + '/' + DELETE_ITEM, payload)
        }
      })
    },
    onClickEdit(id) {
      this.$router.push({name: this.editRouteName, params: {id: id}})
    },
    filterResult() {
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.onHitBottom();
      setTimeout(() => {
        self.state.reset();
      }, 100);
    },
    resetFilters() {
      this.search = '';
      this.language_id = null;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("general.invoice_category")}
    ]);

    this.languages = this.convertArrayToObjectByKey((this.systemLanguages), 'id', 'name');

    this.$store.commit(_MODULE_NAME + '/' + SET_ITEMS, null);
  }
}
</script>

<style scoped>

</style>
